<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640.388 150.031">
    <title>DietPlus</title>
    <path
      class="cls-1"
      d="M296.035,271.194c0-4.872-2.12-6.843-6.673-7.656-1.589-.463-2.543-.812-2.012-2.088l.847-2.2a1.951,1.951,0,0,1,1.8-1.393h34.844c30.186,0,39.825,15.312,39.825,38.744,0,24.127-16.841,43.846-40.142,43.846-13.451,0-20.018-.695-25.631-2.783-1.8-.7-2.859-2.668-2.859-6.148Zm14.933,51.387c0,8.816,5.4,10.9,13.451,10.9,16.523,0,24.255-16.007,24.255-36.655,0-22.968-9-32.016-25.632-32.016H310.968Z"
      transform="translate(-101.074 -222.49)"
    />
    <path
      class="cls-1"
      d="M384.572,293.465c0-4.988-1.641-5.8-6.677-6.959-1.095-.349-2.189-.812-1.533-2.2l.986-2.32c.327-.928.985-.928,1.642-.928h20.14v45.587c0,4.988,1.642,5.8,7,7.076,1.424.232,1.643,1.16,1.2,2.087l-.875,2.32a1.9,1.9,0,0,1-1.86.928H377.675c-.875,0-1.969-.231-1.422-1.972l.657-1.971c.438-1.276,1.313-1.392,2.08-1.624a6.533,6.533,0,0,0,5.582-6.844Zm7.334-37.931c4.378,0,8.1,3.945,8.1,8.584,0,4.408-3.722,8.584-7.991,8.584a8.6,8.6,0,0,1-.109-17.168Z"
      transform="translate(-101.074 -222.49)"
    />
    <path
      class="cls-1"
      d="M428.288,310.517c0,11.368,5.365,21.924,16.421,21.924,6.458,0,9.961-2.669,13.791-7.076,1.1-1.044,4.27.463,3.393,2.32l-3.5,7.54a6.376,6.376,0,0,1-2.3,2.9c-2.517,1.276-8.539,2.32-12.807,2.32-19.484,0-30.43-13.919-30.43-29.811,0-19.372,11.821-30.971,28.022-30.971,14.887,0,22.439,10.672,22.439,26.215,0,2.088-.984,4.64-2.517,4.64Zm17.843-6.264c2.955,0,4.269-1.856,4.269-5.1,0-10.441-5.035-13.8-9.741-13.8-7.773,0-12.371,9.163-12.371,18.907Z"
      transform="translate(-101.074 -222.49)"
    />
    <path
      class="cls-1"
      d="M512.384,285.346l-2.188,2.319H495.747v34.1c0,7.308,2.517,11.02,7.991,11.02a16.54,16.54,0,0,0,4.269-.7c.656-.348,1.642-.579,1.86,0l1.533,3.248a.963.963,0,0,1-.329,1.276l-3.173,1.74a19.8,19.8,0,0,1-8.539,2.088c-12.26,0-18.17-6.032-18.17-18.444V287.665h-9.305v-6.611h9.305v-11.6l9.413-5.337c2.19-1.16,5.145-1.16,5.145.233v16.7h16.637Z"
      transform="translate(-101.074 -222.49)"
    />
    <path
      class="cls-2"
      d="M545.08,327.957c0,4.57,1.764,5.272,7.531,6.444,1.529.234,1.765,1.171,1.295,2.108l-.943,2.11a2.3,2.3,0,0,1-2,.82H526.252c-.943,0-2.119-.235-1.53-1.758l.705-1.874c.471-1.289,1.412-1.406,2.237-1.641,2.941-.468,6-2.226,6-6.209V269.846c0-4.57-2.353-6.326-7.413-7.147-1.766-.351-2.825-.7-2.237-1.991l.942-1.992a2.029,2.029,0,0,1,2-1.288h31.891c12.356,0,24.476,6.442,24.241,21.79-.234,18.746-15.768,26.01-30.6,24.955l-1.648-2.695c-.706-1.171,0-1.522,1.059-1.522,12.944,0,19.769-7.732,19.769-19.331,0-11.95-7.531-16.871-16.591-16.871h-10Z"
      transform="translate(-101.074 -222.49)"
    />
    <path
      class="cls-2"
      d="M609.423,327.957c0,4.57,1.765,5.272,7.413,6.444,1.53.234,1.766,1.171,1.295,2.108l-.942,2.11a2.3,2.3,0,0,1-2,.82H591.419c-.943,0-2.119-.235-1.53-1.758l.705-1.874c.471-1.289,1.412-1.406,2.237-1.641,2.941-.468,6-2.226,6-6.209V264.809c0-4.571-1.765-5.273-7.179-6.327-1.177-.352-2.353-.821-1.646-2.226l1.058-2.11c.354-.819,1.059-.819,1.766-.819h13.533c2.353,0,3.059,1.171,3.059,3.514Z"
      transform="translate(-101.074 -222.49)"
    />
    <path
      class="cls-2"
      d="M666.242,333.7c-5.06,3.749-11.3,7.147-16.475,7.147-11.767,0-17.887-6.092-17.887-20.035V292.341c0-4.57-1.764-5.272-7.178-6.327-1.177-.352-2.353-.821-1.648-2.225l1.06-2.11c.352-.82,1.059-.82,1.765-.82h13.65c1.177,0,2.943.585,2.943,3.867v29.758c0,13.355,2.353,18.98,11.179,18.98,3.883,0,8.12-2.343,12.12-6.679,4.473-4.92,5.06-16.52,5.06-26.711v-7.733c0-4.453-1.764-5.272-7.06-6.327-1.177-.352-2.353-.821-1.648-2.225l1.059-2.11c.353-.82.943-.82,1.648-.82h13.65c1.177,0,2.943.585,2.943,3.867v42.528c0,2.928.941,3.984,1.648,4.686,2.823,2.813,9.767.937,7.649,4.8l-.707,1.64c-.588,1.172-2,1.055-3.53,1.055H673.656c-1.295,0-2.825.117-2.825-2.343v-6.914Z"
      transform="translate(-101.074 -222.49)"
    />
    <path
      class="cls-2"
      d="M700.981,323.153c0-1.171-.118-2.108.471-2.343l2.236-.7c1.53-.585,1.412,3.749,3.765,7.732a12.605,12.605,0,0,0,12,8.085c6,0,12.239-3.514,11.886-11.013-.236-5.976-9.533-10.31-14.945-13.942-6.237-4.335-13.768-6.913-13.768-15.817,0-10.661,12.12-15.7,20.24-15.7a59.285,59.285,0,0,1,12.239,1.172c2,.469,3.058,1.758,3.058,3.279v8.085a2.1,2.1,0,0,1-.469,1.054l-2,1.641c-.705.7-2.353-3.047-3.766-5.507-1.882-3.045-5.529-4.686-9.766-4.686-5.061,0-9.885,2.929-9.649,7.85.235,3.632,4.353,5.858,12.355,10.778,10.592,7.029,16.593,11.013,16.593,19.8,0,13.24-12.239,17.926-21.653,17.926-6.354,0-11.65-.82-15.651-3.28-1.294-.821-3.177-1.641-3.177-3.634Z"
      transform="translate(-101.074 -222.49)"
    />
    <path
      class="cls-2"
      d="M106.982,311.769a20.173,20.173,0,0,1,0-28.528l54.849-54.852a20.193,20.193,0,0,1,28.526,0l54.84,54.852a20.173,20.173,0,0,1,0,28.528l-54.849,54.852a20.193,20.193,0,0,1-28.526,0Z"
      transform="translate(-101.074 -222.49)"
    />
    <path
      class="cls-2"
      d="M231.491,225.659c0,8.244-4.1,14.688-9.92,16.191a10.536,10.536,0,0,1-2.638.332,10.528,10.528,0,0,1,2.6.323c5.837,1.485,9.958,7.939,9.958,16.2,0-7.868,3.736-14.1,9.134-15.955a10.469,10.469,0,0,1,3.423-.569,10.5,10.5,0,0,1-3.218-.5C235.322,239.912,231.491,233.626,231.491,225.659Z"
      transform="translate(-101.074 -222.49)"
    />
    <path
      class="cls-3"
      d="M205.365,265.852h-18.72a13.1,13.1,0,0,0-13.09,13.09V318.35a11.91,11.91,0,1,1-7.294-10.993l1.353.571v-7.47a18.872,18.872,0,1,0,12.895,17.892V298.2h15.242a13.105,13.105,0,0,0,13.091-13.092V269.329A3.476,3.476,0,0,0,205.365,265.852Zm-3.477,19.256a6.144,6.144,0,0,1-6.137,6.138H180.509v-12.3a6.143,6.143,0,0,1,6.136-6.136h15.243Z"
      transform="translate(-101.074 -222.49)"
    />
  </svg>
</template>

<style scoped>
.cls-1 {
  fill: #4c3c35;
}
.cls-2 {
  fill: #f6aa00;
}
.cls-3 {
  fill: #fff;
}
</style>
